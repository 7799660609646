import React from "react";
import { Container, Row } from "react-bootstrap";
import Activity from "../../components/Activity/Activity";
import activity01 from "../../images/activity01.jpg";
import activity03 from "../../images/activity03.JPG";
import activity05 from "../../images/activity05.jpg";
import { useTranslation } from "react-i18next";

const Activities = () => {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <Row className="p-3 listActivities">
          <h1>{t("Activities")}</h1>
          <Activity
            title={t("Article in the local news paper SEYBOUSE TIMES.")}
            date={t("24 Novembre 2021")}
            image={activity05}
            url="https://web.facebook.com/NechmaIssam/photos/pb.112595131236918.-2207520000../115816190914812/?type=3&theater"
          />
          <Activity
            title={t(
              "Our offices in different proinvices and neighbourhoods of Annaba."
            )}
            date={t("23 Novembre 2021")}
            image={activity01}
            url="https://web.facebook.com/112595131236918/posts/115439567619141/?sfnsn=mo&_rdc=1&_rdr"
          />
          <Activity
            title={t("Article in the local news paper ESSAKR.")}
            date={t("22 Novembre 2021")}
            image={activity03}
            url="https://web.facebook.com/NechmaIssam/photos/a.115079747655123/115078970988534/?type=3&theater"
          />
        </Row>
      </Container>
    </>
  );
};

export default Activities;
