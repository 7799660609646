import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "./Activity.css";
const Activity = (props) => {
  return (
    <a className="link_to" href={props.url}>
      <Container>
        <Row className="justify-content-center activity-container">
          <Col fluid="false" lg="3">
            <div className="col__div__img">
              <img className="col__img" src={props.image} alt="activiy" />
            </div>
          </Col>
          <Col lg="6" className="justify-content-center">
            <Row>
              <Col lg="6" md="6">
                <div className="col__date">{props.date}</div>
              </Col>
            </Row>
            <div className="col__title mt-4">{props.title}</div>
            <div>{props.description}</div>
          </Col>
        </Row>
      </Container>
    </a>
  );
};

export default Activity;
