import React, { useState } from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import "./Navigation.css";

const Navigation = () => {
  const { t } = useTranslation();
  const [selectedLang, setSelectedLang] = useState("en");
  const [expanded, setExpanded] = useState(false);

  const changeLanguage = (lang) => {
    setSelectedLang(lang);
    setExpanded(false);
    i18n.changeLanguage(lang);
    document.body.dir = i18n.dir();
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        expanded={expanded}
        style={{ color: "#082c64" }}
        className="TopNavBarSticky"
      >
        <Container>
          <Navbar.Brand className="NavBrandColor" href="#home">
            {t("Issam NECHMA")}
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => setExpanded(expanded ? false : "expanded")}
          />

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto LinksList">
              <NavLink
                to="/"
                className="NavLinkColor"
                onClick={() => setExpanded(false)}
              >
                {t("Home")}
              </NavLink>
              <NavLink
                className="NavLinkColor"
                to="/biography"
                onClick={() => setExpanded(false)}
              >
                {t("Biography")}
              </NavLink>

              <NavLink
                className="NavLinkColor"
                to="/vision"
                onClick={() => setExpanded(false)}
              >
                {t("My vision")}
              </NavLink>
              <NavLink
                className="NavLinkColor"
                to="/values"
                onClick={() => setExpanded(false)}
              >
                {t("My values")}
              </NavLink>

              <NavLink
                className="NavLinkColor"
                to="/activities"
                onClick={() => setExpanded(false)}
              >
                {t("Activities")}
              </NavLink>
              <NavLink
                className="NavLinkColor"
                to="/"
                onClick={() => setExpanded(false)}
              >
                {t("Fields of intervention")}{" "}
              </NavLink>
            </Nav>
            <Nav className="me-auto SocialIcons">
              {selectedLang === "ar" ? (
                <Button
                  className="LanguageButton"
                  onClick={() => changeLanguage("en")}
                >
                  English
                </Button>
              ) : (
                <Button
                  className="LanguageButton"
                  onClick={() => changeLanguage("ar")}
                >
                  العربية
                </Button>
              )}
              <Nav.Link
                className="NavSocialColor"
                href="https://www.facebook.com/Nechma-Issam-%D9%86%D8%B4%D9%85%D8%A9-%D8%B9%D8%B5%D8%A7%D9%85-112595131236918?_rdc=2&_rdr"
                target="_blank"
                aria-label="Facebook"
              >
                <FaFacebook />
              </Nav.Link>
              <Nav.Link
                className="NavSocialColor"
                href="https://dz.linkedin.com/in/issamnechma"
                target="_blank"
                aria-label="LinkedIn"
              >
                <FaLinkedin />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Navigation;
