import React from "react";
import { Container, Row, Nav, Col } from "react-bootstrap";
import { FaFacebook, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs lg="2">
          <Nav>
            <Nav.Link
              className="NavSocialColor"
              href="https://www.facebook.com/Nechma-Issam-%D9%86%D8%B4%D9%85%D8%A9-%D8%B9%D8%B5%D8%A7%D9%85-112595131236918?_rdc=2&_rdr"
              target="_blank"
              aria-label="Facebook"
            >
              <FaFacebook />
            </Nav.Link>
            <Nav.Link
              className="NavSocialColor"
              href="https://dz.linkedin.com/in/issamnechma"
              target="_blank"
              aria-label="Facebook"
            >
              <FaLinkedin />
            </Nav.Link>
          </Nav>
        </Col>
      </Row>
      <Row className="justify-content-md-center mt-2">
        ©️ 2021 Copyright. Issam NECHMA
      </Row>
    </Container>
  );
};

export default Footer;
