import React from "react";
import Home from "./pages/Home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Footer, Navigation } from "./components";
import Biography from "./pages/Biography/Biography";
import "./App.css";
import Values from "./pages/Values/Values";
import Vision from "./pages/Vision/Vision";
import Intervention from "./pages/Intervention/Intervention";
import Activities from "./pages/Activities/Activities";

function App() {
  return (
    <Router>
      <Navigation />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/biography" exact element={<Biography />} />
        <Route path="/vision" exact element={<Vision />} />
        <Route path="/values" element={<Values />} />
        <Route path="/activities" element={<Activities />} />
        <Route path="/intervention" element={<Intervention />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
