import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import img from "../../images/HomePage.png";
import { useTranslation } from "react-i18next";
import "./Home.css";

const Home = () => {
  const { t } = useTranslation();
  return (
    <>
      <Container fluid="true" className="homeContainer w-100 h-100 m-0">
        <Row>
          <Col xs="12" lg="7">
            <img src={img} alt="Candidat" className="HomeImage" />
          </Col>
          <Col xs lg="5">
            <div className=" text-white">
              <Row className="m-2 mt-4">
                <h1 className="slogan">{t("Believe in the future")}</h1>
              </Row>
              <Row className="m-2 mt-4">
                <p>
                  {t(
                    "My visions come from the core of my belief in the potential that the industrial, economical and cultural reform of the region has, and the impact it can have on improving the social conditions of every citizen, they are based on a number of objectives that aim to exploit our full capacities."
                  )}
                </p>

                <ul>
                  <li>
                    {t(
                      "Identify and strengthen the sectors of activity with a high job creation potential;"
                    )}
                  </li>
                  <li>
                    {t("Implementing Social and Solidarity Economy projects;")}
                  </li>
                  <li>
                    {t(
                      "Give back to the youth its role as an active force for the region's take-off;"
                    )}
                  </li>
                  <li>
                    {t(
                      "Exploit the propitious geographical position of the region;"
                    )}
                  </li>
                  <li>
                    {t(
                      "Exploit the environment and geo-economic potential of the wilaya to make it a regional pole of attraction;"
                    )}
                  </li>
                  <li>
                    {t(
                      "Eliminate the obstacles to the growth of small and medium sized businesses and the creation of employment;"
                    )}
                  </li>
                  <li>{t("Train a qualified workforce;")}</li>
                  <li>
                    {t(
                      "Generate investments in the fields of agriculture, tourism, internet and communication technologies;"
                    )}
                  </li>
                  <li>
                    {t(
                      "Generate the needed conditions for the promotion of the entrepreneurial mindset and the creation of startupss;"
                    )}
                  </li>
                </ul>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
