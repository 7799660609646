import { Container, Row, Col } from "react-bootstrap";
import "./Vision.css";
import { useTranslation } from "react-i18next";

const Vision = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md="2" lg="1" xs="1"></Col>
        <Col md="7" lg="8">
          <h1 className="text-center">{t("My vision")}</h1>
          <div className="div__vision">
            <p>{t("Dear Compatriots, Sisters, and Brothers,")}</p>
            <p>
              {t(
                "I humbly put myself forward to represent you in the upcoming local elections in Annaba."
              )}
            </p>
            <p>
              {t(
                'First and foremost, I would like to get one thing out of the way. I am here to serve the people and not simply do "politics".'
              )}
            </p>
            <p>
              {t(
                'I totally understand that many of you may associate negative connotations with the word "politics" and have reservations about "politicians". But, at its heart, politics is a noble endeavour designed to champion people\'s interests, be the voice of the weak and marginalised, charter a better future for the collective, and improve social justice.'
              )}
            </p>
            <p>
              {t(
                "Driven by these core principles, I have decided to extend my voice, skills, and expertise to all Algerians and Annabis without exception or reserve."
              )}
            </p>

            <p>
              {t(
                "My enduring love for our country and firm belief that Algerians can and will forge a better future will be my guiding light in your service."
              )}
            </p>
            <p>
              {t(
                "Having said that, I will not be drawn to making empty promises of a better future and greater riches. However, I will promise you one thing. I will not spare any efforts, and I shall work tirelessly towards making Annaba a prosperous city that can compete nationally and internationally."
              )}
            </p>

            <p>
              {t(
                "For this to happen, we need to re-establish a solid link between academia and the industry. Our youth is a formidable force that needs to be harnessed, trained, looked after, and integrated into every aspect of our economic redevelopment. The increased economic output will help us invest in better housing, health, education, and so forth."
              )}
            </p>
            <p>
              {t(
                "Let's be under no illusion, though. Change does not happen overnight. Change is not easy. Change is fraught with challenges. It requires a lot of energy and time to be invested in establishing and executing a solid and well-studied plan."
              )}
            </p>
            <p>
              {t(
                "Algeria can no longer afford to be stuck in the past and must adopt a forward-looking and holistic posture across all sectors to cement itself a place in a highly competitive world. I am a firm believer that the limit is not the sky but rather our own ambitions and expectations. So, let's dream and be ambitious about our future."
              )}
            </p>
            <p>
              {t(
                "I sincerely hope you will endorse my vision and entrust me with the chance to be your voice so that we can together seize the opportunity of making a better future for all of us."
              )}
            </p>
            <p>{t("Sincerely yours,")}</p>
            <p>{t("Issam Nechma")}</p>

            {/*         <ul>
              <li>
                {t(
                  "Identify and strengthen the sectors of activity with a high job creation potential;"
                )}
              </li>
              <li>
                {t("Implementing Social and Solidarity Economy projects;")}
              </li>
              <li>
                Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec
                consectetuer ligula vulputate sem tristique cursus. Nam nulla
                quam, gravida non, commodo a, sodales sit amet, nisi.
              </li>
              <li>
                Pellentesque fermentum dolor. Aliquam quam lectus, facilisis
                auctor, ultrices ut, elementum vulputate, nunc.
              </li>
              <li>
                Morbi in sem quis dui placerat ornare. Pellentesque odio nisi,
                euismod in, pharetra a, ultricies in, diam. Sed arcu. Cras
                consequat.
              </li>
              <li>
                Praesent dapibus, neque id cursus faucibus, tortor neque egestas
                augue, eu vulputate magna eros eu erat. Aliquam erat volutpat.
                Nam dui mi, tincidunt quis, accumsan porttitor, facilisis
                luctus, metus.
              </li>
              <li>
                Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec
                consectetuer ligula vulputate sem tristique cursus. Nam nulla
                quam, gravida non, commodo a, sodales sit amet, nisi.
              </li>
              <li>
                Pellentesque fermentum dolor. Aliquam quam lectus, facilisis
                auctor, ultrices ut, elementum vulputate, nunc.
              </li>
                </ul>*/}
          </div>
        </Col>
        <Col md="2" lg="1" xs="1"></Col>
      </Row>
    </Container>
  );
};

export default Vision;
