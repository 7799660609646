import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      "translated-paragraph":
        "We are going to translate this paragraph - how will it be in Polish?",
    },
  },
  ar: {
    translation: {
      "translated-paragraph": "فقرة تمت ترجمتها",
      Home: "الرئيسية",
      Biography: "السيرة الذاتية",
      "My values": "مبادئي",
      "Fields of intervention": "مجالات التدخل",
      Activities: "الأنشطة",
      "Issam NECHMA": "نشمة عصام",
      "Believe in the future": "الثقة في المستقبل",
      "Issam NECHMA, General Manager of an industrial Group and president of the Al-Taraji Sports Club Of el-Hadjar, holder a bachelor of science in financial mathematics and a master's degree in aerospace engineering. A member and an affiliate mechanical engineer of the IMechE institute of England since 2007.":
        "نشمة عصام، مدير عام لمجمع صناعي ورئيس نادي الترجي الرياضي الحجار، متحصل على شهادة ليسانس في الرياضيات المالية وعلى ماجيستير هندسة طيران، عضو في معهد IMechE الانجليزي ومهندس ميكانيكي معترف به من طرف المعهد منذ 2007. ",
      "Issam was born in 1986 in Annaba, where he studied until he passed the Baccalaureate exam in Naturel Sciences in 2003. He then moved to England to pursue his higher education, obtaining in 2007 a Master of Engineering (MEng) in Aerospace Engineering from Queen Mary University (University of London) and an aviation certificate in “Stability and Control” from Cranfield Airport. He also earned a Bachelor’s degree in Financial Mathematics from the University of Surrey in 2010.":
        "ولد نشمة عصام في 1986 بولاية عنابة أين زاول دراسته حتى المرحلة الثانوية وتحصله على شهادة البكالوريا سنة 2003 في شعبة العلوم الطبيعية. انتقل فيما بعد إلى انجلترا من أجل اتمام الدراسات العليا فتحصل بداية على شهادة في اللغة الانجليزية من king's college- London سنة 2004, ثم تخصص في هندسة الطيران ليتحصل على شهادة ماجيستير في الهندسة سنة 2007 من جامعة لندن Queen Mary University of London وشهادة طيران تخصص ثبات وتحكم من Cranfield Airport وتحصل أيضا على شهادة ليسانس علوم في الرياضيات المالية سنة 2010 من University of Surrey. ",
      "Upon acquiring the required professional experience, Issam returned home to participate in developing his city and the country.":
        "بعد استكمال مشواره الدراسي واكتساب الكم الكافي من الخبرات، رجع نشمة عصام لموطنه راميا الى المشاركة في تطوير المجتمع المحلي والوطن بما أمكن من وسائل.",

      "He subsequently took different roles at the family business, established by his father in the 1990s. He was then handed over the responsibility of running it in 2014. As the General Manager, he played a pivotal role in transforming the family enterprise into an influential industrial group in the local and national market across five sectors, demonstrating excellent management and business development skills.":
        "شغل بعد عودته مناصب مختلفة في الشركة العائلية المؤسسة من طرف والده سنة 1990 حتى سلمت له مسؤولية تسييرها سنة 2014.  كمدير عام للشركة، لعب عصام دورا محوريا في تحويل المؤسسة العائلية الى مجمع صناعي ذو تأثير هام على الصعيدين المحلي والوطني في خمس (05) ميادين مختلفة، مثبتا بذلك قدرته العالية على التسيير وتطوير الأعمال.",

      "Issam is a stronger believer in knowledge sharing. He thus continuously leverages his expertise to support his compatriots in creating business opportunities and building a better tomorrow for all Algerians.":
        "عصام من أشد المؤمنين بأهمية مشاركة المعرفة ما يشجعه باستمرار على استغلال خبرته لتعزيز مشاريع التنمية والتطوير المحليين ومساعدة أبناء وطنه في خلق فرص عمل وظروف النمو الملائمة لبناء غد أفضل لجميع الجزائريين.",

      "Front El-Moustakbel Party proved to be the best fit with Issam’s principles and goals, becoming a Member of Annaba’s Provincial Assembly (APW) in December 2021.":
        " كان حزب جبهة المستقبل الوجهة الأكثر تلاءما مع مبادئه وأهدافه فانضم إليه، ليصبح بعد ذلك عضوا في المجلس الشعبي الولائي لولاية عنابة في 2021.",

      "Issam recognizes the weight of responsibility bestowed upon him by his fellow Annabis and Algerians. He will work tirelessly to be the voice of the weak and marginalized, charter a better future for the collective, and improve social justice.":
        "عصام على دراية تامة بثقل المسؤولية التي كلفه بها أبناء وطنه وكل الجزائريين عند اختياره لتمثيلهم وسيعمل بلا كلل ليكون صوت الضعفاء والمهمشين، ليساعد في رسم مستقبل أفضل للمجتمع وتحسين العدالة الاجتماعية",
      //Values
      "My Values": "مبادئي",
      "Who I am today, is the result of two main situations I lived, I was born and raised in Algeria and my whole personality and mindset was built under the Algerian customs and traditions. I also had the chance to live elsewhere for a while, where I met different people, societies, cultures and minds, at each point I would find myself comparing each situation to my own people and country, and each time I’d see more potential in us. My values never changed, they are no different from any Algerian, I am a person who is very proud of our national identity, our culture and traditions yet knows that we still have a long way to social, economic and cultural development. I believe in our great abilities and competencies yet I am well aware of the many obstacles ahead. I believe that each person should have their voice heard but also should hear and accept that of others. I believe that we have all means and tools for great prosperity yet we lack responsibility and motivation.":
        "الشخص الذي أنا عليه اليوم هو نتيجة لظرفين رئيسيين عشتهما، ولدت وترعرعت في الجزائر، وبنيت شخصيتي ومبادئي بالكامل في ظل العادات والتقاليد الجزائرية. ثم أتيحت لي فرصة الإقامة في مكان آخر لفترة من الوقت، أين قابلت أشخاصًا، مجتمعات وثقافات مختلفة. في كل مرحلة كنت أجدني أقارن كل موقف بحال شعبي وموطني، وفي كل مرة كنت أرانا أكثر إمكانية. لم تتغير قيمي ومبادئي يوما، فهي لا تختلف عن أي جزائري، أنا شخص فخور جدًا بهويتنا الوطنية، عاداتنا وتقاليدنا ومع ذلك أعلم أنه لا يزال أمامنا طريق طويل لتحقيق التنمية الاجتماعية، الاقتصادية والثقافية. أنا أؤمن بقدراتنا وكفاءاتنا العظيمة ومع ذلك فأنا على دراية تامة بوجود العديد من العقبات في طريقنا. أؤمن أن لكل شخص حق إسماع صوته ولكن عليه واجب سماع صوت الآخر وقبوله. أعلم أن لدينا كل الوسائل والموارد لتحقيق الازدهار التام ولكننا نفتقر حس المسؤولية والتحفيز.",

      "I believe that it is time for our country to go through its economic reform, for our people to unite and for our youth to start building the future.":
        "أعتقد أن الوقت قد حان لبلدنا أن يمر بإصلاحه الاقتصادي، وأن يتحد شعبنا ويبدأ شبابنا في بناء المستقبل.",

      "My values are no different from yours, I believe in our country, in our nation and in one Algeria for all Algerians. I believe in the future.":
        "مبادئي لا تختلف عن مبادئك، أنا أؤمن ببلدنا وبشعبنا، أؤمن بجزائر واحدة لكل الجزائريين.أنا أؤمن بالمستقبل.",

      //My vision
      "My vision": "رؤيتي",
      "Dear Compatriots, Sisters, and Brothers,":
        "أعزائي المواطنين، أخواتي وإخوتي،",
      "I humbly put myself forward to represent you in the upcoming local elections in Annaba.":
        "أقدم لكم نفسي بتواضع لتمثيلكم في الانتخابات المحلية المقبلة في عنابة.",
      'First and foremost, I would like to get one thing out of the way. I am here to serve the people and not simply do "politics".':
        "أولاً وقبل كل شيء، أود أن أقرب شيئا مهماً من أذهانكم، أنا هنا لخدمة الشعب وليس لممارسة السياسة.",
      'I totally understand that many of you may associate negative connotations with the word "politics" and have reservations about "politicians". But, at its heart, politics is a noble endeavour designed to champion people\'s interests, be the voice of the weak and marginalised, charter a better future for the collective, and improve social justice.':
        "أنا على إدراك تام بأن العديد منكم قد يربط كلمة 'سياسة' بمفاهيم سلبية وأنّ لمعظمكم تحفظات على 'السياسيين'. ولكن السياسة في جوهرها مسعى نبيل يهدف إلى الدفاع عن مصالح الناس، إيصال صوت الضعفاء والمهمشين، تحسين العدالة الاجتماعية وضمان مستقبل أفضل للجميع.",
      "Driven by these core principles, I have decided to extend my voice, skills, and expertise to all Algerians and Annabis without exception or reserve.":
        "انطلاقا من هذه المبادئ الأساسية، قررت أنْ أوسع صوتي، مهاراتي وخبرتي لتشمل جميع الجزائريين وكل مواطن عنابي دون استثناء أو تحفظ وسيكون حبي الدائم لبلدنا وإيماني الراسخ بمقدرة الجزائريين وعزمهم على بناء مستقبل أفضل الضوء الذي يرشدني لخدمتكم. ",
      "My enduring love for our country and firm belief that Algerians can and will forge a better future will be my guiding light in your service.":
        "",
      "Having said that, I will not be drawn to making empty promises of a better future and greater riches. However, I will promise you one thing. I will not spare any efforts, and I shall work tirelessly towards making Annaba a prosperous city that can compete nationally and internationally.":
        "بعد قولي هذا، أنا لن أقدم لكم أي وعود فارغة بمستقبل مبهر وثروات أكثر، مع ذلك، سوف أعدكم بشيء واحد، أنّي لن أذخر أي جهد، وسأعمل بلا كلل من أجل جعل عنابة مدينة مزدهرة قادرة على المنافسة على الصعيدين الوطني والدولي. ",

      "Algeria can no longer afford to be stuck in the past and must adopt a forward-looking and holistic posture across all sectors to cement itself a place in a highly competitive world. I am a firm believer that the limit is not the sky but rather our own ambitions and expectations. So, let's dream and be ambitious about our future.":
        "لم يعد للجزائر إمكانية التشبث بالماضي بعد الآن، أصبح عليها اعتماد موقف شمولي وتطلعي في جميع القطاعات لترسيخ مكانتها في عالم اليوم الذي أصبح شديد التنافس. أنا من أشد المؤمنين بأنّ حدودنا ليست السماء، بل هي طموحاتنا وتوقعاتنا الخاصة. لذلك دعونا نحلم و لنكن طموحين بشأن مستقبلنا. لكن علينا أن نتذكر دائماً أن التغيير لا يحدث بين عشية وضحاها، التغيير ليس سهلا، والتغيير محفوف بالتحديات. يتطلب الكثير من الطاقة والوقت لوضع وتنفيذ خطة محكمة ومدروسة جيدا.",

      "For this to happen, we need to re-establish a solid link between academia and the industry. Our youth is a formidable force that needs to be harnessed, trained, looked after, and integrated into every aspect of our economic redevelopment. The increased economic output will help us invest in better housing, health, education, and so forth.":
        " لتحقيق ذلك، نحتاج إلى إعادة تأسيس صلة متينة بين الأوساط الأكاديمية ومختلف المجالات الصناعية. شبابنا قوة هائلة تحتاج إلى التدريب والرعاية والإدماج في كل جانب من جوانب التنمية الاقتصادية. وستساعدنا زيادة الإنتاج الاقتصادي على الاستثمار في مشاريع الإسكان، الصحة والتعليم وغيرهم. ",

      "Let's be under no illusion, though. Change does not happen overnight. Change is not easy. Change is fraught with challenges. It requires a lot of energy and time to be invested in establishing and executing a solid and well-studied plan.":
        "لكن علينا أن نتذكر دائماً أن التغيير لا يحدث بين عشية وضحاها، التغيير ليس سهلا، والتغيير محفوف بالتحديات. يتطلب الكثير من الطاقة والوقت لوضع وتنفيذ خطة محكمة ومدروسة جيدا.",
      "I sincerely hope you will endorse my vision and entrust me with the chance to be your voice so that we can together seize the opportunity of making a better future for all of us.":
        "آمل مخلصا أن تؤيدوا رؤيتي وأن توكلوا إليّ الفرصة لأكون صوتكم حتى نتمكن معا من اغتنام فرصة صنع مستقبل أفضل لنا جميعا",

      "Sincerely yours,": "تحياتي الخالصة،",
      "Issam Nechma": "عصام نشمة",

      //activities
      "24 Novembre 2021": "24 نوفمبر 2021",
      "23 Novembre 2021": "23 نوفمبر 2021",
      "22 Novembre 2021": "22 نوفمبر 2021",

      "Article in the local news paper ESSAKR.":
        "مقال في الجريدة المحلية الصقر حول ترشح نشمة عصام لمحليات 2021.",

      "Article in the local news paper SEYBOUSE TIMES.":
        "مقال في الجريدة المحلية سيبوس تايمز حول ترشح نشمة عصام لمحليات 2021.",
      "Our offices in different proinvices and neighbourhoods of Annaba.":
        "مكاتبنا في مختلف بلديات و أحياء ولاية عنابة.",

      //home page
      "My visions come from the core of my belief in the potential that the industrial, economical and cultural reform of the region has, and the impact it can have on improving the social conditions of every citizen, they are based on a number of objectives that aim to exploit our full capacities.":
        "تنبع رؤيتي من صميم بنجاعة الإصلاح الصناعي والاقتصادي والثقافي للمنطقة، ومدى تأثيره على تحسين الظروف الاجتماعية لكل مواطن، فهي تقوم على عدد من الأهداف التي ترمي إلى الاستغلال الكلي لمواردنا وإمكانياتنا:",
      "Identify and strengthen the sectors of activity with a high job creation potential;":
        "تحديد وتعزيز قطاعات النشاط ذات الإمكانات العالية لخلق فرص العمل؛",
      "Implementing Social and Solidarity Economy projects;":
        "بناء مشاريع تحت ظل الاقتصاد الاجتماعي والتضامني",
      "Give back to the youth its role as an active force for the region's take-off;":
        "إعادة دور الشباب كقوة فاعلة في الانتعاش الاقتصادي للمنطقة؛",
      "Exploit the propitious geographical position of the region;":
        "الاستغلال الكلي للموقع الجغرافي الإستراتيجي والإمكانيات الاقتصادية للمنطقة لجعلها قطب جذب إقليمي؛",
      "Exploit the environment and geo-economic potential of the wilaya to make it a regional pole of attraction;":
        "",
      "Eliminate the obstacles to the growth of small and medium sized businesses and the creation of employment;":
        "إزالة العوائق التي تحول دون نمو الشركات الصغيرة والمتوسطة ",
      "Train a qualified workforce;": "تكوين قوى عاملة مؤهلة؛",
      "Generate investments in the fields of agriculture, tourism, internet and communication technologies;":
        "توليد استثمارات في مجالات الزراعة والسياحة وتقنيات الإنترنت والاتصالات",
      "Generate the needed conditions for the promotion of the entrepreneurial mindset and the creation of startupss;":
        "خلق الظروف اللازمة لتعزيز ثقافة ريادة الأعمال وبناء الشركات الناشئة",
    },
  },
};

const DETECTION_OPTIONS = {
  order: ["localStorage", "navigator"],
  caches: ["localStorage"],
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  detection: DETECTION_OPTIONS,
  resources,
  fallbackLng: "en",
});

export default i18n;
