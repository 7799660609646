import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Values.css";
import { useTranslation } from "react-i18next";

const Values = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Row>
        <Col>
          <h1>{t("My Values")}</h1>
          <div className="div__values">
            <p>
              {t(
                "Who I am today, is the result of two main situations I lived, I was born and raised in Algeria and my whole personality and mindset was built under the Algerian customs and traditions. I also had the chance to live elsewhere for a while, where I met different people, societies, cultures and minds, at each point I would find myself comparing each situation to my own people and country, and each time I’d see more potential in us. My values never changed, they are no different from any Algerian, I am a person who is very proud of our national identity, our culture and traditions yet knows that we still have a long way to social, economic and cultural development. I believe in our great abilities and competencies yet I am well aware of the many obstacles ahead. I believe that each person should have their voice heard but also should hear and accept that of others. I believe that we have all means and tools for great prosperity yet we lack responsibility and motivation."
              )}
            </p>
            <p>
              {t(
                "I believe that it is time for our country to go through its economic reform, for our people to unite and for our youth to start building the future."
              )}
            </p>
            <p>
              {t(
                "My values are no different from yours, I believe in our country, in our nation and in one Algeria for all Algerians. I believe in the future."
              )}
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Values;
