import { Container, Row, Col } from "react-bootstrap";
import img from "../../images/background.jpg";
import { useTranslation } from "react-i18next";
import "./Biography.css";
const Biography = () => {
  const { t } = useTranslation();
  return (
    <>
      <Container fluid="true" className="BioContainer">
        <Row>
          <Col lg="1"></Col>
          <Col xs="12" lg="4">
            <img src={img} alt="Profil" className="ImageProfil" />
          </Col>
          <Col xs lg="5" className="m-5 bio">
            <h1 className="bioHeader">{t("Biography")}</h1>
            <p>
              {t(
                "Issam NECHMA, General Manager of an industrial Group and president of the Al-Taraji Sports Club Of el-Hadjar, holder a bachelor of science in financial mathematics and a master's degree in aerospace engineering. A member and an affiliate mechanical engineer of the IMechE institute of England since 2007."
              )}
            </p>
            <div className="Divider"></div>
            <p>
              {t(
                "Issam was born in 1986 in Annaba, where he studied until he passed the Baccalaureate exam in Naturel Sciences in 2003. He then moved to England to pursue his higher education, obtaining in 2007 a Master of Engineering (MEng) in Aerospace Engineering from Queen Mary University (University of London) and an aviation certificate in “Stability and Control” from Cranfield Airport. He also earned a Bachelor’s degree in Financial Mathematics from the University of Surrey in 2010."
              )}
            </p>
            <p>
              {t(
                "Upon acquiring the required professional experience, Issam returned home to participate in developing his city and the country."
              )}
            </p>
            <p>
              {t(
                "He subsequently took different roles at the family business, established by his father in the 1990s. He was then handed over the responsibility of running it in 2014. As the General Manager, he played a pivotal role in transforming the family enterprise into an influential industrial group in the local and national market across five sectors, demonstrating excellent management and business development skills."
              )}
            </p>
            <p>
              {t(
                "Issam is a stronger believer in knowledge sharing. He thus continuously leverages his expertise to support his compatriots in creating business opportunities and building a better tomorrow for all Algerians."
              )}
            </p>
            <p>
              {t(
                "Front El-Moustakbel Party proved to be the best fit with Issam’s principles and goals, becoming a Member of Annaba’s Provincial Assembly (APW) in December 2021."
              )}
            </p>

            <p>
              {t(
                "Issam recognizes the weight of responsibility bestowed upon him by his fellow Annabis and Algerians. He will work tirelessly to be the voice of the weak and marginalized, charter a better future for the collective, and improve social justice."
              )}
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Biography;
