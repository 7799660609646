import React from "react";
import { Container, Row, Card, Button } from "react-bootstrap";
import placeHolder from "../../images/placeHolder.png";
import "./Intervention.css";

const Intervention = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Card className="m-4" style={{ width: "18rem" }}>
          <Card.Img variant="top" src={placeHolder} />
          <Card.Body>
            <Card.Title>Card Title</Card.Title>
            <Card.Text>
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </Card.Text>
            <Button variant="primary">Go somewhere</Button>
          </Card.Body>
        </Card>

        <Card className="m-4" style={{ width: "18rem" }}>
          <Card.Img variant="top" src={placeHolder} />
          <Card.Body>
            <Card.Title>Card Title</Card.Title>
            <Card.Text>
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </Card.Text>
            <Button variant="primary">Go somewhere</Button>
          </Card.Body>
        </Card>

        <Card className="m-4" style={{ width: "18rem" }}>
          <Card.Img variant="top" src={placeHolder} />
          <Card.Body>
            <Card.Title>Card Title</Card.Title>
            <Card.Text>
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </Card.Text>
            <Button variant="primary">Go somewhere</Button>
          </Card.Body>
        </Card>

        <Card className="m-4" style={{ width: "18rem" }}>
          <Card.Img variant="top" src={placeHolder} />
          <Card.Body>
            <Card.Title>Card Title</Card.Title>
            <Card.Text>
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </Card.Text>
            <Button variant="primary">Go somewhere</Button>
          </Card.Body>
        </Card>

        <Card className="m-4" style={{ width: "18rem" }}>
          <Card.Img variant="top" src={placeHolder} />
          <Card.Body>
            <Card.Title>Card Title</Card.Title>
            <Card.Text>
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </Card.Text>
            <Button variant="primary">Go somewhere</Button>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
};

export default Intervention;
